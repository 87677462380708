import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import MainContent from "@components/content";
import MiniProduct from "@components/miniProduct";
import Filters from "@components/filters";
import Email from "@components/email";

import * as Types from '../../types';

interface FilterOptions {
  colors: string[];
  sizes: string[]; 
  sort: string;
}

export default function Catalog() {
  const { id } = useParams();
  const [category, setCategory] = useState<Types.Category>();
  const [products, setProducts] = useState<Types.Product[]>();

  const [activeSection, setActiveSection] = useState<number[]>([]);
  const [limit, _] = useState(50);
  const [start, setStart] = useState(0);
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState<FilterOptions>({
    colors: [],
    sizes: [], 
    sort: "0"
  });

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_URL}/products/c/${id}?limit=${limit}&start=${start}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      if (start === 0) {
        setProducts(response.data);
      } else {
        setProducts((prevProducts: any) => [...prevProducts, ...response.data]);
      }
      setLoading(false);
    }).catch(error => {
      console.log(error);
      setLoading(false);
    });

    axios.get(`${import.meta.env.VITE_API_URL}/categories/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      setCategory(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, [id,  limit, start]);
  
  const handleScroll = useCallback((e: any) => {
    const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if (bottom && !loading) {
      setLoading(true);
      setStart((prevStart) => prevStart + limit);
    }
  }, [loading, limit]);

  useEffect(() => {
    const container = document.getElementById('productListContainer');
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const filterProducts = (
    products: Types.Product[] | undefined,
    filters: FilterOptions,
    categoryIds: number[],
  ) => {
    const filteredProducts = products?.filter(product => {
      const matchesCategory = categoryIds.length > 0
        ? categoryIds.includes(
            typeof product.category === 'number' 
                ? product.category 
                : product.category.id
          )
        : true;
  
      const matchesColors = filters?.colors?.length > 0
        ? filters.colors.some(color =>
            product.colors.some(productColor => productColor.color === color)
          )
        : true;
  
      const matchesSizes = filters?.sizes?.length > 0
        ? filters.sizes.some(size =>
            product.sizes.some(productSize => productSize.name === size)
          )
        : true;
  
      return matchesCategory && matchesSizes && matchesColors;
    });
  
    if (!filteredProducts) {
      return [];
    }

    switch (filters?.sort) {
      case '1':
        return filteredProducts.sort((a, b) => a.price - b.price);
      case '2':
        return filteredProducts.sort((a, b) => b.price - a.price);
      default:
        return filteredProducts;
    }
  };
  
  const filteredProducts = filterProducts(products, filters, activeSection!);

  return (
    <MainContent cover={true} image={`${category?.coverImg}`}>    
      <SEO 
        title={`${category?.name}`}
        description={`${category?.miniDesc}`}
        ogImage={`${category?.coverImg}`}
        ogUrl={`${category?.linkCover}`}
      />

      <h2>{category?.name}</h2>

      <Filters 
        category={category}
        sections={category?.parents ? category?.parents : []}
        products={products}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        selectedFilters={filters}
        setFilters={setFilters}
      />

      <div className={css.productList}>
        {filteredProducts?.map((product: Types.Product) => (
          <MiniProduct 
            id={product.id}
            title={product.name}
            price={product.price}
            image={product.images[0]}
          />
        ))}

        {loading && <div>загружаем...</div>}
      </div>
      <Email />
    </MainContent>
  )
}