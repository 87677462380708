import { useEffect, useState } from 'react'
import css from './index.module.css';
import Content from '@components/adminNavigation';
import axios from "axios";
import { toast } from 'sonner';
import SEO from '@components/seo';

export default function Settings() {
  const [settings, setSettings] = useState<any>();
 
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_ADM_URL}/site`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
        setSettings(response.data);
    }).catch(error => {
      console.log(error);
    });
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
  
    setSettings((prev: any) => {
      if (!prev) return prev;
  
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = (event: any) => {
    const formData = new FormData(event.target);
    event.preventDefault();

    axios.post(`${import.meta.env.VITE_API_ADM_URL}/site`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    })
    .then(_ => {
      toast.success('Информация обновлена')
    })
    .catch(error => {
      console.log(error);
      toast.error('Ошибка');
    });
  };
  
  return (
    <Content>
      <SEO 
        title={'Управление настройками'}
        description={'Админ-панель Sweet Cat Shop'}
        ogUrl={`https://sweetcatshop.ru/`}
      />

      <h1 className={css.head}>Настройки сайта</h1>

      <form className={css.form} onSubmit={handleSubmit}>
        <div className={css.col1}>
            <div className={css.inputGrid}>
              <label>Картинка на главной</label>
              <input name='mainCover' placeholder='Ссылка на картинку на главной' value={settings?.mainCover} onChange={handleChange} />
            </div>

            <div className={css.inputGrid}>
              <label>Ссылка для кнопки "подробнее" на главной</label>
              <input name='mainCoverLink' placeholder='Ссылка для кнопки "подробнее" на главной' onChange={handleChange} value={settings?.mainCoverLink} />
            </div>

            <div className={css.inputGrid}>
              <label>Ссылка на условия соглашения</label>
              <input name='terms' placeholder='Ссылка на условия соглашения' onChange={handleChange} value={settings?.terms} />
            </div>

            <div className={css.inputGrid}>
              <label>Ссылка на политику обработки данных</label>
              <input name='privacy' placeholder='Ссылка на политику обработки данных' onChange={handleChange} value={settings?.privacy} />
            </div>

            <div className={css.inputGrid}>
              <label>Номер телефона поддержки</label>
              <input name='phone' placeholder='Номер телефона поддержки' onChange={handleChange} value={settings?.phone} />
            </div>

            <div className={css.inputGrid}>
              <label>Почта поддержки</label>
              <input name='email' placeholder='Почта поддержки' onChange={handleChange} value={settings?.email} />
            </div>

            <div className={css.inputGrid}>
              <label>Цвет в Header</label>
              <input name='headerColor' placeholder='Цвет Header' type='color' onChange={handleChange} value={settings?.headerColor} />
            </div>

            <button className={css.saveBtn} type='submit'>Сохранить изменения</button>
        </div>
      </form>
    </Content>
  );
}
