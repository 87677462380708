import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';
import { toast } from "sonner";

import SEO from "@components/seo";
import MainContent from "@components/content";
import MiniProduct from "@components/miniProduct";
import Email from "@components/email";
import Tabs from "@components/tabs";
import ColorTabs from "@components/colorTabs";

import * as Types from '../../types';
import useUserData from "@hooks/useUserData";

export default function Product() {
  const { id } = useParams();
  const navigate = useNavigate();

  const token = localStorage.getItem('authToken');
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState([]);
  const [color, setColor] = useState(1);
  const [size, setSize] = useState(1);
  const [selImg, setSelImg] = useState(0);
  const { favoriteData, refreshFavData, refreshCartData } = useUserData();

  const parsedId = id ? parseInt(id, 10) : undefined;
  const isFavorite = parsedId !== undefined && favoriteData.some(fav => fav.product.id === parsedId);

  useEffect(() => {
    setSelImg(0);

    axios.get(`${import.meta.env.VITE_API_URL}/product/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      setProduct(response.data);
    }).catch(error => {
      console.log(error)
    });

    axios.get(`${import.meta.env.VITE_API_URL}/products`, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      setProducts(response.data);
    }).catch(error => {
      console.log(error)
    });
  }, [id]);

  const addToCart = () => {
    if (!token) return navigate(`/auth`);

    const data = {
      prodID: id,
      params: { size: size, color: color }
    }

    axios.post(`${import.meta.env.VITE_API_URL}/user/cart`, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(() => {
      toast.success('Товар добавлен в корзину');
      refreshCartData();
    }).catch(() => {
      toast.error('Возникла ошибка, не смогли добавить товар');
    });
  };

  const addToFav = () => {
    if (!token) return navigate(`/auth`);

    axios.post(`${import.meta.env.VITE_API_URL}/user/favorites`, { prodID: id }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(() => {
      isFavorite ? toast.success('Товар удален из избранных') : toast.success('Товар добавлен в избранные');
      refreshFavData();
    }).catch(() => {
      toast.error('Возникла ошибка, не смогли добавить товар');
    });
  };

  const formatNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  return (
    <MainContent cover={false}>
      {product.map((info: Types.Product) => (
      <>
        <SEO 
          title={info.name}
          description={info.desc}
          ogImage={info.images[0]}
          ogUrl={`https://sweetcatshop.ru/product/${info.id}`}
        />

        <div className={css.mainInfo}>
          <div className={css.imageBlock}>
            <div className={css.imagesList}>
              {info.images.map((img, index) => (
                <img src={img} alt={info.name} onClick={() => setSelImg(index)} />
              ))}
            </div>

            <img src={info.images[selImg]} alt={info.name} />
          </div>

          <div className={css.textBlock}>
            <a>{info.brand}</a>
            <h1>{info.name}</h1>

            {info.colors.length > 0 &&
              <ColorTabs 
                sections={info.colors}
                active={color}
                setActive={setColor}
              />
            }
            
            <p>{info.desc}</p>
            
            {info.sizes.length > 0 &&
              <div className={css.size}>
                <div>
                  <h3>размеры</h3>
                  <a>гид по размерам</a>
                </div>
  
                <Tabs 
                  sections={info.sizes}
                  active={size}
                  setActive={setSize}
                />
              </div>
            }

            <div className={css.availability}>
              <div className={css.greenCircle} />
              <a>в наличии</a>
            </div>

            <div className={css.addToCart} onClick={addToCart}>
              <a>добавить в корзину</a>
              <a>{formatNumber(info.price)} ₽</a>
            </div>

            <div className={css.addToFavorite} onClick={addToFav}>
              <a>{isFavorite ? "удалить из избранного" : "добавить в избранное"}</a>
              {isFavorite ? <i className="fi fi-ss-heart"></i> : <i className="fi fi-rr-heart"></i> }
            </div>

            <a className={css.secure}><i className="fi fi-rr-lock"></i> все платежи защищены</a>
          </div>
        </div>

        <h2 className={css.h2}>смотрите еще</h2>

        <div className={css.productList}>
          {products.map((prod: Types.Product) => (
            prod.id != info.id &&
            <MiniProduct 
              id={prod.id}
              title={prod.name}
              price={prod.price}
              image={prod.images[0]}
            />
          ))}
        </div>
      </>
      ))}
      <Email />
    </MainContent>
  )
}