import { useState, useEffect, FC } from "react";
import { useNavigate } from "react-router-dom";
import css from './index.module.css';
import * as Types from '../../types';
import axios from "axios";

import CartProduct from "@components/cartProduct";
import useUserData from "@hooks/useUserData";

interface Popup {
    showPopup: boolean, 
    setShowPopup: any
}

export const Cart: FC<Popup> = ({ showPopup, setShowPopup }) => {
    const navigate = useNavigate();
    const { cartData } = useUserData();
    const token = localStorage.getItem('authToken');

    const calculateTotal = () => {
        return cartData.reduce((total, item) => {
            return total + item.product.price * item.count;
        }, 0);
    };

    const formatNumber = (num: number) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    return (
        <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
            <div className={css.popupBG} onClick={() => setShowPopup(false)} />
            <div className={css.popupBody}>
                <div className={css.popupContent}>
                    {token ?
                    <>
                        <div className={css.productGrid}>
                            {cartData.length > 0 ? cartData.map((cart: Types.CartData) => (
                                <CartProduct 
                                    id={cart.id}
                                    prodID={cart.product.id}
                                    image={cart.product.images[0]}
                                    title={cart.product.name}
                                    desc={<div className={css.tinfo}>{cart.product.colors.find(i => i.id === cart.params.color) && <div className={css.color} style={{ color: cart.product.colors.find(i => i.id === cart.params.color)?.color }} /> } {cart.product.sizes.find(i => i.id === cart.params.size) && cart.product.sizes.find(i => i.id === cart.params.size)?.name}</div>}
                                    price={cart.product.price}
                                    count={cart.count}
                                    buttons={true}
                                />
                            )) : <a>корзина пуста, добавьте сюда что-нибудь</a>}
                        </div>
                        
                        {cartData.length > 0 &&
                        <>
                            <div className={css.total}>
                                <a>итог</a>
                                <a>{formatNumber(calculateTotal())} ₽</a>
                            </div>
                            
                            <div className={css.checkout} onClick={() => navigate(`/checkout`)}>оформить заказ</div>
                        </>
                        }
                    </>
                    :
                    <>
                        <h3 style={{ margin: 0 }}>мы вас не узнали, авторизуйтесь, пожалуйста</h3>
                        <div className={css.checkout} onClick={() => navigate(`/auth`)}>войти в аккаунт</div>
                    </>
                    }
                </div>
            </div>
        </div>
    )
}

export const Favorite: FC<Popup> = ({ showPopup, setShowPopup }) => {
    const navigate = useNavigate();
    const { favoriteData } = useUserData();
    const token = localStorage.getItem('authToken');

    return (
        <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
            <div className={css.popupBG} onClick={() => setShowPopup(false)} />
            <div className={css.popupBody}>
                <div className={css.popupContent}>
                    {token ? 
                        <div className={css.productGrid}>
                            {favoriteData.length > 0 ? favoriteData.map((fav: Types.Favorites) => (
                                <CartProduct 
                                    id={fav.product.id}
                                    prodID={fav.product.id}
                                    image={fav.product.images[0]}
                                    title={fav.product.name}
                                    desc={''}
                                    price={fav.product.price}
                                />
                            )) : <a>нет ничего в избранном, добавьте сюда что-то</a>}
                        </div>
                        :
                        <>
                            <h3 style={{ margin: 0 }}>мы вас не узнали, авторизуйтесь, пожалуйста</h3>
                            <div className={css.checkout} onClick={() => navigate(`/auth`)}>войти в аккаунт</div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export const Search: FC<Popup> = ({ showPopup, setShowPopup }) => {
    const [query, setQuery] = useState<string>('');
    const [products, setProducts] = useState<Types.Product[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
  
    useEffect(() => {
      const fetchSuggestions = async () => {
        if (query.length < 3) {
            setProducts([]);
            return;
        }
  
        setLoading(true);

        try {  
          const response = await fetch(`${import.meta.env.VITE_API_URL}/search?query=${query}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
  
          const data = await response.json();
          setProducts(data);
        } catch (error) {
          console.error('Ошибка получения подсказок', error);
          setProducts([]);
        } finally {
          setLoading(false);
        }
      };
  
      const timer = setTimeout(fetchSuggestions, 300);
      return () => clearTimeout(timer);
    }, [query]);

    return (
        <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
            <div className={css.popupBG} onClick={() => setShowPopup(false)} />
            <div className={css.popupBody}>
                <div className={css.popupContent}>
                    <input type="text" placeholder="что нужно найти?" value={query} onChange={(e) => setQuery(e.target.value)} />
                    <div className={css.checkout}>найти сейчас</div>

                    {loading && (
                        <a className={css.info}>загрузка...</a>
                    )}
                            
                    {products.length > 0 &&
                        <div className={css.products}>
                            {products.map(item => (
                                <CartProduct 
                                    id={item.id}
                                    image={item.images[0]}
                                    title={item.name}
                                    desc={''}
                                    price={item.price}
                                />
                            ))}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export const Categories: FC<Popup> = ({ showPopup, setShowPopup }) => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState<Types.Category[]>([]);

    useEffect(() => {
      axios.get(`${import.meta.env.VITE_API_URL}/categories`, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then(response => {
        setCategories(response.data);
      }).catch(error => {
        console.log(error);
      });
    }, []);
  
    return (
        <div className={showPopup ? css.popup : `${css.popup} ${css.hidden}`}>
            <div className={css.popupBG} onClick={() => setShowPopup(false)} />
            <div className={css.popupBody}>
                <div className={css.popupContent} style={{ left: 0, width: 450 }}>
                    <div className={css.categories}>
                        {categories.map(item => (
                            item.isHeader === 1 &&
                            <div className={css.category} onClick={() => navigate(`/catalog/${item.id}`)}>
                                <img src={item.icon} alt={item.name} />
                                {item.name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}