import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import css from './index.module.css';
import Logo from '@assets/images/logo.svg';
import { Cart, Favorite, Search, Categories } from "./popups";
import useUserData from "@hooks/useUserData";

export default function Header() {
    const navigate = useNavigate();
    const [isSticky, setIsSticky] = useState(false);
    const [activePopup, setActivePopup] = useState(null); 
    const { userData, siteData } = useUserData();
    const token = localStorage.getItem('authToken');

    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const togglePopup = (popupName: any) => {
        setActivePopup(activePopup === popupName ? null : popupName);
    };

    return (
    <>
        <Cart showPopup={activePopup === 'cart'} setShowPopup={() => togglePopup('cart')} />
        <Favorite showPopup={activePopup === 'favorite'} setShowPopup={() => togglePopup('favorite')} />
        <Search showPopup={activePopup === 'search'} setShowPopup={() => togglePopup('search')} />
        <Categories showPopup={activePopup === 'categories'} setShowPopup={() => togglePopup('categories')} />

        <header className={`${css.header} ${isSticky ? css.sticky : ''}`}>
            <div className={css.body}>
                <div className={css.leftMenu}>
                    <img src={Logo} alt="Logo" className={css.logo} onClick={() => navigate('/')} />

                    <div className={css.menu}>
                        <a onClick={() => togglePopup('categories')} style={{ color: siteData.headerColor || '#000' }}>каталог</a>
                        <Link to={`/`} style={{ color: siteData.headerColor || '#000' }}>компания</Link>
                        <Link to={`/`} style={{ color: siteData.headerColor || '#000' }}>доставка</Link>
                        <Link to={`/`} style={{ color: siteData.headerColor || '#000' }}>контакты</Link>
                    </div>
                </div>

                <div className={css.rightMenu}>
                    {userData.access >= 1 && 
                        <i className="fi fi-rr-settings" style={{ color: siteData.headerColor || '#000' }} onClick={() => navigate('/manage')}></i>
                    }

                    {token && 
                    <>
                        <i className="fi fi-rr-heart" style={{ color: siteData.headerColor || '#000' }} onClick={() => togglePopup('favorite')}></i>
                        <i className="fi fi-rr-shopping-bag" style={{ color: siteData.headerColor || '#000' }} onClick={() => togglePopup('cart')}></i>
                    </>
                    }
                    <i className="fi fi-rr-search" style={{ color: siteData.headerColor || '#000' }} onClick={() => togglePopup('search')}></i>

                    <Link to={`/account`} className={`${!token && css.auth}`}>{token ? <img className={css.avatar} src={userData.avatar} alt="avatar" /> : `войти в аккаунт`}</Link>
                </div>
            </div>
        </header>
    </>
    )
}