import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import css from './index.module.css';

import SEO from "@components/seo";
import Logo from '@assets/images/logo.svg';
import CartProduct from "@components/cartProduct";
import useUserData from "@hooks/useUserData";
import Addresses from "@components/addresses";
import BankCards from '@components/bankCards';
import * as Types from '../../types';

import ConnCard from '@assets/images/connCard.svg';
import SberPay from '@assets/images/sberPay.svg';
import SBP from '@assets/images/sbp.svg';
import TinkoffPay from '@assets/images/tinkoffPay.svg';
import YooMoney from '@assets/images/yoomoney.svg';

import EmptyCart from "@pages/system/emptyCart";
import { toast } from "sonner";

export default function Main () {
    const { cartData } = useUserData();

    return (
    <>
        {cartData.length > 0 ? <Checkout /> : <EmptyCart />}
    </>
    )
}

function Checkout() {
  const navigate = useNavigate();
  const [deductBonus, setDeductBonus] = useState(false);

  const [showPopupAddresses, setShowPopupAddresses] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<Types.Address | null>(null);
  const [selectedMethod, setSelectedMethod] = useState<string>('');

  const [showPopupBank, setShowPopupBank] = useState(false);
  const [bankCard, setBankCard] = useState<Types.ConnCard | null>(null);

  const { userData, cartData, siteData } = useUserData();
  const [getMeBack, setGetMeBack] = useState<Types.BonusGetMeBack>();

  const [promoCode, setPromoCode] = useState<string>('');
  const [promoCodeInfo, setPromoCodeInfo] = useState<Types.Promocode>();

  const calculateCart = () => {
    const cartTotal = cartData.reduce((total, item) => {
        return total + item.product.price * item.count;
    }, 0);

    return cartTotal;
  };


  const calculateTotal = () => {
    let cartTotal = calculateCart();
    if (deductBonus) cartTotal -= getMeBack?.max_use_bonus!;
    if (promoCodeInfo) cartTotal -= promoCodeInfo?.price;
    if (selectedAddress) cartTotal += selectedAddress?.deliveryPrice;
    if (cartTotal <= 0) return 1;
    
    return cartTotal;
  };

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_URL}/checkout/bonus`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    }).then(response => {
      setGetMeBack(response.data);
    }).catch(error => {
        console.log(error);
    });
  }, []);

  const usePromocode = () => {
    if (promoCode.length <= 3) return toast.error('Введите промокод');
    axios.post(`${import.meta.env.VITE_API_URL}/usePromocode`, { promocode: promoCode }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    }).then(response => {
        setPromoCodeInfo(response.data);
        toast.success('Промокод применен');
    }).catch(error => {
        console.log(error);
        toast.error('Промокод не применен');
    });
  }

  const selectCard = () => {
    setSelectedMethod('bank_card')
    setShowPopupBank(true);
  }

  const goToCheckout = () => {
    if (!selectedAddress || !selectedMethod || !bankCard) return toast.error('Запоните все поля');

    const data = {
        address: selectedAddress.uuid,
        payType: selectedMethod,
        cardID: bankCard.id,
        promoCode: promoCode || '',
        bonusDeduct: deductBonus
    }

    axios.post(`${import.meta.env.VITE_API_URL}/checkout`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
    }).then(response => {
        if (response.data.url) {
            window.location.href = response.data.url;
        } else {
            navigate(`/order/${response.data.orderID}`);
            toast.success('Заказ оформлен, спасибо');
        }
    }).catch(error => {
        console.log(error);
        toast.error('Возникла ошибка');
    });
  }

  const formatNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  return (
    <>
        <Addresses showPopup={showPopupAddresses} setShowPopup={setShowPopupAddresses} selectedAddress={selectedAddress} setSelectedAddress={setSelectedAddress} />
        <BankCards showPopup={showPopupBank} setShowPopup={setShowPopupBank} selectedCard={bankCard} setSelectedCard={setBankCard} />
        <SEO 
            title="Оформление заказа"
            description="Оформление заказа в Sweet Cat Shop"
            ogUrl="https://sweetcatshop.ru/"
        />

        <header className={css.header}>
            <div className={css.body}>
                <img className={css.logo} src={Logo} alt="Logo" />
                <img className={css.avatar} src={userData.avatar} alt="avatar" />
            </div>
        </header>

        <div className={css.body}>
            <div className={css.col1}>
                <div className={css.input}>
                    <label>контактная информация <Link className={css.change} to='/account'>изменить</Link></label>
                </div>

                <div className={css.inputFlex}>
                    <input placeholder="имя" defaultValue={userData.firstName} disabled/>
                    <input placeholder="фамилия" defaultValue={userData.lastName} disabled/>
                </div>

                <input placeholder="номер телефона" style={{ marginTop: 10 }} defaultValue={userData.phone} disabled/>
                
                <div className={css.input}>
                    <label>способ доставки {!selectedAddress && (<i className={`fi fi-rr-triangle-warning ${css.red}`} />)}</label>
                </div>

                {selectedAddress ? 
                    <div className={`${css.deliveryType} ${css.deliveryActive}`} onClick={() => setShowPopupAddresses(true)}>
                        <div className={css.textInfo}>
                            <a className={css.name}>{selectedAddress.address}</a>
                            <a className={css.desc}>{selectedAddress.type === 0 ? "заберете в магазине" : "доставим курьерской службой СДЕК"}</a>
                        </div>
    
                        <a className={css.price}>{selectedAddress.deliveryPrice} ₽</a>
                    </div>
                :
                    <div className={`${css.deliveryType}`} onClick={() => setShowPopupAddresses(true)}>
                        <div className={css.textInfo}>
                            <a className={css.name}>выберите способ доставки</a>
                            <a className={css.desc}>или добавьте новый</a>
                        </div>
                    </div>
                }

                <div className={css.input} style={{ gap: 0 }}>
                    <label>способ оплаты {!selectedMethod && (<i className={`fi fi-rr-triangle-warning ${css.red}`} />)}</label>
                    <a className={css.paymentSafe}>все платежи защищены</a>
                </div>


                <div className={`${css.paymentType} ${selectedMethod === 'bank_card' && css.paymentActive}`} onClick={() => selectCard()}>
                    <img src={ConnCard} alt="ConnCard" style={{ width: 20 }} /> 
                    <a>{bankCard ? `Выбрана карта *${bankCard.data.cardLast4}` : 'Привязанная карта'}</a>
                </div>

                <div className={`${css.paymentType} ${selectedMethod === 'yoo_money' && css.paymentActive}`} onClick={() => setSelectedMethod('yoo_money')}>
                    <img src={YooMoney} alt="YooMoney" style={{ width: 20 }} /> 
                    <a>ЮMoney</a>
                </div>

                <div className={`${css.paymentType} ${selectedMethod === 'sberbank' && css.paymentActive}`} onClick={() => setSelectedMethod('sberbank')}>
                    <img src={SberPay} alt="SberPay" style={{ width: 20 }} /> 
                    <a>SberPay</a>
                </div>

                <div className={`${css.paymentType} ${selectedMethod === 'tinkoff_bank' && css.paymentActive}`} onClick={() => setSelectedMethod('tinkoff_bank')}>
                    <img src={TinkoffPay} alt="T-Pay" style={{ width: 20 }} /> 
                    <a>T-Pay</a>
                </div>

                <div className={`${css.paymentType} ${selectedMethod === 'sbp' && css.paymentActive}`} onClick={() => setSelectedMethod('sbp')}>
                    <img src={SBP} alt="SBP" style={{ width: 20 }} /> 
                    <a>СБП</a>
                </div>

                <div className={css.devHidden}>
                    <div className={`${css.checkout} ${(selectedMethod === '' || selectedAddress === null) && css.disabled}`} onClick={() => goToCheckout()}>оплатить сейчас</div>
                    <a className={css.checkoutP}>нажимая кнопку “оплатить сейчас (частями)” я соглашаюсь с политикой обработки персональных данных</a>
                </div>

                <div className={`${css.footer} ${css.devHidden}`}>
                    <a href={siteData.terms}>Политика возврата</a>
                    <a href={siteData.privacy}>Политика конфиденциальности</a>
                </div>
            </div>

            <div className={css.col2}>
                <div className={css.productList}>
                    {cartData.map(cart => (
                        <CartProduct 
                            id={cart.id}
                            prodID={cart.product.id}
                            image={cart.product.images[0]}
                            title={cart.product.name}
                            desc={<div className={css.tinfo}>{cart.product.colors.find(i => i.id === cart.params.color) && <div className={css.color} style={{ color: cart.product.colors.find(i => i.id === cart.params.color)?.color }} /> } {cart.product.sizes.find(i => i.id === cart.params.size) && cart.product.sizes.find(i => i.id === cart.params.size)?.name}</div>}
                            price={cart.product.price}
                            count={cart.count}
                            buttons={true}
                        />
                    ))}
                </div>

                <div className={css.checkInfo}>
                    <div className={css.promoCode}>
                        <input type="text" placeholder="промокод" onChange={(event) => setPromoCode(event.target.value)} />
                        <div className={css.promoArrow} onClick={usePromocode}><i className="fi fi-rr-arrow-small-right"></i></div>
                    </div>

                    {userData.loyality &&
                        <div className={`${css.loyality} ${deductBonus && css.active}`}>
                            <h3>бонусный счёт <a>№{userData.loyality.card_num}</a></h3>

                            <div className={css.checkInfoText}>
                                <a className={css.name}>начислим</a>
                                <b className={css.value}>{getMeBack?.bonus_value} <img src={Logo} alt="Logo" style={{ width: 20, position: 'relative', top: 2.5, marginLeft: 5 }} /></b>
                            </div>

                            <div className={css.checkInfoText}>
                                <a className={css.name}>списать <b style={{ color: "#000" }}>{getMeBack?.max_use_bonus} <img src={Logo} alt="Logo" style={{ width: 20, position: 'relative', top: 2.5, marginLeft: 5 }} /></b></a>
                                <label className={css.switch} style={{ marginBottom: 0}}>
                                    <input type="checkbox" name="connect" id="connectPayment" checked={deductBonus} onChange={() => setDeductBonus(!deductBonus)} />
                                    <span className={`${css.switchSlider} ${css.round}`}></span>
                                </label>
                            </div>
                        </div>
                    }

                    <div className={css.checkInfoText}>
                        <a className={css.name}>подытог</a>
                        <a className={css.value}>{formatNumber(calculateCart())} ₽</a>
                    </div>
                    
                    {promoCodeInfo &&
                        <div className={css.checkInfoText}>
                            <a className={css.name}>промокод</a>
                            <a className={css.value}>-{formatNumber(promoCodeInfo?.price)} ₽</a>
                        </div>
                    }
                    
                    <div className={css.checkInfoText}>
                        <a className={css.name}>доставка</a>
                        <a className={css.value}>{selectedAddress?.deliveryPrice || '—'} ₽</a>
                    </div>

                    <div className={css.checkInfoText}>
                        <a className={css.name} style={{ fontWeight: 600, fontSize: 22, color: "#000" }}>итог</a>
                        <a className={css.value} style={{ fontWeight: 600 }}>{formatNumber(calculateTotal())} ₽</a>
                    </div>

                    <div className={css.devHiddenDesk}>
                        <div className={`${css.checkout} ${(selectedMethod === '' || selectedAddress === null) && css.disabled}`} onClick={() => goToCheckout()}>оплатить сейчас</div>
                        <a className={css.checkoutP}>нажимая кнопку “оплатить сейчас (частями)” я соглашаюсь с политикой обработки персональных данных</a>
                    </div>

                    <div className={`${css.footer} ${css.devHiddenDesk}`}>
                        <a href={siteData.terms}>Политика возврата</a>
                        <a href={siteData.privacy}>Политика конфиденциальности</a>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}